//测试
export const HelloRootUrl = "/hello"
export const test_box = "/hello/TestFlex"
export const BgColor = '/hello/BgColor'
export const TestSlot = '/hello/TestSlot'
export const test_event = '/hello/test_event'

const routes = [
    {
        path: HelloRootUrl,
        redirect: '/hello/home',
    },
    {
        path: '/hello/home',
        component: () => import('@/views/hello/TestHome')
    },
    {
        path: '/hello/TestAxios',
        component: () => import('@/views/hello/TestAxios.vue')
    },
    {
        path: test_box,
        component: () => import('@/views/hello/TestFlex.vue')
    },
    {
        path: BgColor,
        component: () => import('@/views/hello/TestBgColor.vue')
    },
    {
        path: '/hello/parent_child/Parent',
        component: () => import('@/views/hello/parent_child/Parent')
    },
    {
        path: TestSlot,
        component: () => import('@/views/hello/test_slot/TestSlot.vue')
    },
    {
        path: test_event,
        component: () => import('@/views/hello/test_event.vue')
    },
    {
        path: '/hello/test_object_fit',
        component: () => import('@/views/hello/test_object_fit')
    },
    {
        path: '/hello/test_form',
        component: () => import('@/views/hello/test_form')
    },
    {
        path: '/hello/test_search_page',
        component: () => import('@/views/hello/test_search_page')
    },
    {
        path: '/hello/TestUpload',
        component: () => import('@/views/hello/TestUpload.vue')
    },
    {
        path: '/hello/test_popup',
        component: () => import('@/views/hello/test_popup')
    },
    {
        path: '/hello/test_echarts',
        component: () => import('@/views/hello/test_echarts')
    },
    {
        path: '/hello/test_img_highlight',
        component: () => import('@/views/hello/test_img_highlight')
    },
    {
        path: '/hello/test_vue-cropper',
        component: () => import('@/components/WrdVueCropper')
    },
]
export default routes

